import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type {
	PetitionUpdateDeleteMutation,
	PetitionUpdateDeleteMutationVariables,
} from './deletePetitionUpdate.graphql';
import { PetitionUpdateDelete } from './deletePetitionUpdate.graphql';

export async function deletePetitionUpdate(
	{ gql: { fetch } }: UtilityContext,
	{ petitionId, id }: { petitionId: string; id: string },
): Promise<void> {
	const { data } = await fetch<PetitionUpdateDeleteMutation, PetitionUpdateDeleteMutationVariables>({
		query: PetitionUpdateDelete,
		variables: { id, petitionId },
		rejectOnError: true,
	});

	if (data?.deletePetitionUpdate.result !== 'SUCCESS') {
		throw new Error('Failed to delete update');
	}
}
