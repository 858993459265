import { useCallback } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';

import { usePetitionUpdatePageData } from 'src/app/pages/petitionUpdate/details/pageContext';
import { useCurrentUser } from 'src/app/shared/hooks/session';
import type { ShareActionsOptions } from 'src/app/shared/hooks/share';

import type { Channel } from './types';

export function useGetShareText(): ShareActionsOptions<Channel>['getText'] {
	const {
		data: {
			update: {
				title,
				petition: { ask },
			},
		},
	} = usePetitionUpdatePageData();
	const { translate } = useI18n();
	const user = useCurrentUser();

	return useCallback(
		(channel, type, { url }) => {
			switch (channel) {
				case 'twitter':
					return title;
				case 'email':
					if (type === 'title') return translate('fe.pages.petition_update.email_subject', { petitionTitle: ask });
					return translate('fe.pages.petition_update.email_body', {
						petitionTitle: ask,
						title,
						url,
						from: user?.firstName || '',
					});
				default:
					return '';
			}
		},
		[title, user, ask, translate],
	);
}
