import { useCallback, useMemo } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import { useTracking } from '@change-corgi/core/react/tracking';

import { usePetitionUpdatePageData } from 'src/app/pages/petitionUpdate/details/pageContext';

type Result = ModelHookResult<
	{
		description: string;
		isSimpleUrlDescription: boolean;
		creationDate: string;
		embeddedMedia?: { html: string | null; aspectRatio: number | null };
		link?: {
			url: string;
			label: string;
		};
	},
	{
		handleLinkClick: () => void;
	}
>;

export function usePageBody(): Result {
	const {
		data: { update },
	} = usePetitionUpdatePageData();
	const { translate } = useI18n();
	const track = useTracking();

	const { description, createdAtLocalized } = update;

	return {
		data: {
			description,
			isSimpleUrlDescription: useMemo(() => {
				try {
					if (/(^\s+|\s+$)/.test(description)) return false;
					return !!new URL(description);
				} catch (e) {
					return false;
				}
			}, [description]),
			creationDate: createdAtLocalized,
			embeddedMedia: (update.__typename !== 'FeaturedNewsPetitionUpdate' && update.embeddedMedia) || undefined,
			link:
				update.__typename === 'FeaturedNewsPetitionUpdate'
					? {
							url: update.newsSource.url,
							label: translate('fe.components.updates_feed.media_mention.read_full_article'),
						}
					: undefined,
		},
		actions: {
			handleLinkClick: useCallback(() => {
				if (update.__typename === 'FeaturedNewsPetitionUpdate') {
					const domain = (() => {
						try {
							return new URL(update.newsSource.url).origin;
						} catch (e) {
							return undefined;
						}
					})();
					void track('featured_news_link_click', {
						media_url: update.newsSource.url,
						media_url_domain: domain,
						view_context: 'petitions_updates_show',
					});
				}
			}, [update, track]),
		},
	};
}
