import type { JSX } from 'react';

import { withPrefetchedData } from '@change-corgi/core/react/prefetch';
import { useKnownAvailableParams } from '@change-corgi/core/react/router';
import { Loader } from '@change-corgi/design-system/components/progressiveDisclosure';

import type { PetitionUpdatePagePrefetchedDataProps } from 'src/app/pages/petitionUpdate/details/pageContext';
import {
	PetitionUpdatePageContextProvider,
	prefetchPageContext,
} from 'src/app/pages/petitionUpdate/details/pageContext';

import { Layout } from '../layout';

import { PetitionUpdateErrorHandler } from './ErrorHandler';

export const PetitionUpdate = withPrefetchedData(
	function PetitionUpdate({ prefetchedData }: PetitionUpdatePagePrefetchedDataProps): JSX.Element {
		const { petitionSlugOrId, updateId } = useKnownAvailableParams<{ petitionSlugOrId: string; updateId: string }>();

		return (
			<PetitionUpdatePageContextProvider
				petitionSlugOrId={petitionSlugOrId}
				updateId={updateId}
				prefetchedData={prefetchedData}
				errorHandler={PetitionUpdateErrorHandler}
				loadingFallback={<Loader size="m" my={256} mx="auto" />}
			>
				<Layout />
			</PetitionUpdatePageContextProvider>
		);
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchDependencies: [Layout],
		prefetchData: async (context) =>
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			prefetchPageContext(context.params.petitionSlugOrId!, context.params.updateId!, context),
	},
);
