import type { PropsWithChildren } from 'react';

import { ButtonLink } from '@change-corgi/design-system/components/actions';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconRaisedFist } from '@change-corgi/design-system/icons';
import { Box } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { useActionButton } from './hooks/useActionButton';

type Props = {
	to: string;
	socialProof?: boolean;
	buttonName: string;
};

export function ActionButton({ to, socialProof, buttonName, children }: PropsWithChildren<Props>) {
	const {
		data: { socialProofLabel, hiddenOnMobile },
	} = useActionButton();

	return (
		<Box
			sx={{
				position: ['fixed', 'initial'],
				zIndex: 2000,
				left: 0,
				bottom: hiddenOnMobile ? '-1000px' : 0,
				width: '100%',
				boxShadow: [
					'0px -0.7px 1.4px rgba(0, 0, 0, 0.07), 0px -1.9px 4px rgba(0, 0, 0, 0.05), 0px -4.5px 10px rgba(0, 0, 0, 0.05), 0px -15px 32px rgba(0, 0, 0, 0.04)',
					'none',
				],
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'&:focus-within': {
					bottom: '0px',
					outline: 'none',
				},
			}}
			mt={[0, 16]}
		>
			<Box sx={{ backgroundColor: ['primary-greyBackground', 'initial'] }} px={[16, 0]} py={[8, 0]}>
				{socialProof && (
					<Box
						sx={{
							display: ['flex', 'none'],
							flexDirection: 'row',
							justifyContent: 'center',
							alignItems: 'center',
							gap: 8,
							borderRadius: 18,
						}}
						mb={8}
					>
						<Icon icon={iconRaisedFist} />
						<Text size="small">{socialProofLabel}</Text>
					</Box>
				)}
				<ButtonLink to={to} sx={{ width: ['100%', 'auto'] }} data-qa={`${buttonName}-button`}>
					{children}
				</ButtonLink>
			</Box>
		</Box>
	);
}
