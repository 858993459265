import { PageMessage } from '@change-corgi/design-system/components/alerts';

import { PetitionUnderReviewBanner } from 'src/app/shared/components/banners/petitionUnderReview';

import { usePageBanner } from './hooks/usePageBanner';

export function PageBanner() {
	const {
		data: { message, showUnderReviewBanner },
	} = usePageBanner();

	return (
		<>
			{showUnderReviewBanner && <PetitionUnderReviewBanner />}
			{message && <PageMessage containerVariant="small" role="alert" message={message} severity="critical" />}
		</>
	);
}
