import { useCallback } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import { useNavigate } from '@change-corgi/core/react/router';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	usePetitionUpdatePageData,
	usePetitionUpdatePageState,
} from 'src/app/pages/petitionUpdate/details/pageContext';
import { useConfirm } from 'src/app/shared/hooks/modals';
import { useLoginState } from 'src/app/shared/hooks/session';

import { deletePetitionUpdate } from './deletePetitionUpdate';

type Result = ModelHookResult<
	{
		canEdit: boolean;
		editUrl: string;
	},
	{
		deletePetitionUpdate: () => Promise<void>;
	}
>;

export function usePetitionUpdateActions(): Result {
	const {
		data: {
			update: {
				id,
				petition: { id: petitionId, slug, viewerCanEdit },
			},
		},
	} = usePetitionUpdatePageData();
	const [, { setPageError, clearPageError }] = usePetitionUpdatePageState();
	const utilityContext = useUtilityContext();
	const { translate } = useI18n();
	const confirm = useConfirm({
		title: translate('fe.components.updates_feed.delete_update_modal.title'),
		text: translate('fe.components.updates_feed.delete_update_modal.subtitle'),
	});
	const navigate = useNavigate();
	const loginState = useLoginState();

	return {
		data: {
			canEdit: loginState === 'AUTHENTICATED' && viewerCanEdit,
			editUrl: `/p/${encodeURIComponent(slug)}/u/${id}/edit`,
		},
		actions: {
			deletePetitionUpdate: useCallback(async () => {
				clearPageError();
				const result = await confirm();
				if (!result) return;
				try {
					await deletePetitionUpdate(utilityContext, { id, petitionId });
					navigate(`/p/${encodeURIComponent(slug)}`);
				} catch (e) {
					setPageError(translate('fe.errors.generic_try_again'));
				}
			}, [translate, navigate, confirm, utilityContext, id, slug, petitionId, setPageError, clearPageError]),
		},
	};
}
