import { createStateContext } from '@change-corgi/core/react/context';

import { INITIAL_STATE } from './initialState';
import { mutations } from './mutations';
import type { PetitionUpdatePageState } from './types';

export const {
	StateContext: PetitionUpdatePageStateContext,
	StateProvider: PetitionUpdatePageStateContextProvider,
	useStateContext: usePetitionUpdatePageState,
} = createStateContext<PetitionUpdatePageState, typeof mutations>({
	name: 'PetitionUpdatePageStateContext',
	mutations,
	initialState: INITIAL_STATE,
});
