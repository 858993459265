import type { PrefetchContext, PrefetchedDataProps } from '@change-corgi/core/react/prefetch';

import { useCountryCode, useLocale } from 'src/app/shared/hooks/l10n';
import { useCurrentUserId } from 'src/app/shared/hooks/session';
import type { MergedState } from 'src/app/shared/hooks/state';
import { useMergedStates } from 'src/app/shared/hooks/state';

import { prefetchPetitionUpdatePageDataFromApi, usePetitionUpdatePageDataFromApi } from './pageData';
import { prefetchPetitionUpdatePageFcm, useAsyncPetitionUpdatePageFcm } from './pageFcm';
import {
	prefetchPetitionUpdatePageFcmExperiments,
	useAsyncPetitionUpdatePageFcmExperiments,
} from './pageFcmExperiments';

export type PetitionUpdatePagePrefetchedContext = Readonly<{
	pageData: Parameters<typeof usePetitionUpdatePageDataFromApi>[0];
}>;

export type PetitionUpdatePageCombinedState = MergedState<
	ReturnType<typeof usePetitionUpdatePageDataFromApi>,
	ReturnType<typeof useAsyncPetitionUpdatePageFcmExperiments>,
	ReturnType<typeof useAsyncPetitionUpdatePageFcm>
>;

export function usePageContext(
	petitionSlugOrId: string,
	updateId: string,
	prefetchedData?: PetitionUpdatePagePrefetchedContext,
): PetitionUpdatePageCombinedState {
	const userId = useCurrentUserId();
	const locale = useLocale();
	const countryCode = useCountryCode();
	return useMergedStates(
		usePetitionUpdatePageDataFromApi(prefetchedData?.pageData, {
			petitionSlugOrId,
			updateId,
			userId,
			locale,
			countryCode,
		}),
		useAsyncPetitionUpdatePageFcmExperiments(),
		useAsyncPetitionUpdatePageFcm(),
	);
}

export async function prefetchPageContext(
	petitionSlugOrId: string,
	updateId: string,
	context: PrefetchContext,
): Promise<PetitionUpdatePagePrefetchedContext> {
	const [pageData] = await Promise.all([
		prefetchPetitionUpdatePageDataFromApi(context, {
			petitionSlugOrId,
			updateId,
			userId: context.session?.user?.id,
			...context.l10n,
		}),
		prefetchPetitionUpdatePageFcmExperiments(context),
		prefetchPetitionUpdatePageFcm(context),
	]);

	return { pageData };
}

export type PetitionUpdatePagePrefetchedDataProps = PrefetchedDataProps<PetitionUpdatePagePrefetchedContext>;
