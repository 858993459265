import { Image } from '@change-corgi/design-system/components/media';
import { Box } from '@change-corgi/design-system/layout';

import { usePetitionUpdateMedia } from './hooks/usePetitionUpdateMedia';

export function PetitionUpdateMedia() {
	const {
		data: { photo },
	} = usePetitionUpdateMedia();

	if (photo) {
		return (
			<Box>
				<Image
					src={[photo.mobileUrl, photo.url]}
					fetchPriority="high"
					aspectRatio="16/9"
					fit="contain"
					alt=""
					sx={{
						width: ['100%', 600],
						borderRadius: 'standard',
						// removes an unwanted padding under the image
						verticalAlign: 'top',
					}}
					data-qa="petition-update-photo"
				/>
			</Box>
		);
	}

	return null;
}
