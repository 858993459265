import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { NotFoundError, UnderReviewError } from 'src/shared/error';

import { getViewerSignature } from 'src/app/shared/api/signature';
import { differenceInDays } from 'src/app/shared/utils/dates';

import type {
	PetitionUpdateDataCommonFieldsFragment,
	PetitionUpdateDataDmResponseUpdateFieldsFragment,
	PetitionUpdateDataFeaturedNewsUpdateFieldsFragment,
	PetitionUpdateDataMediaFieldsFragment,
	PetitionUpdateDataOrgFieldsFragment,
	PetitionUpdateDataPetitionFieldsFragment,
	PetitionUpdateDataQuery,
	PetitionUpdateDataQueryVariables,
	PetitionUpdateDataStaffUpdateFieldsFragment,
	PetitionUpdateDataStarterUpdateFieldsFragment,
	PetitionUpdateDataUserFieldsFragment,
} from './api.graphql';
import { PetitionUpdateData } from './api.graphql';

export type PetitionUpdateCommonData = PetitionUpdateDataCommonFieldsFragment & PetitionUpdateDataMediaFieldsFragment;
export type StarterPetitionUpdate = PetitionUpdateDataCommonFieldsFragment &
	PetitionUpdateDataStarterUpdateFieldsFragment;
export type StaffPetitionUpdate = PetitionUpdateDataCommonFieldsFragment & PetitionUpdateDataStaffUpdateFieldsFragment;
export type FeaturedNewsPetitionUpdate = PetitionUpdateDataCommonFieldsFragment &
	PetitionUpdateDataFeaturedNewsUpdateFieldsFragment;
export type DmResponsePetitionUpdate = PetitionUpdateDataCommonFieldsFragment &
	PetitionUpdateDataDmResponseUpdateFieldsFragment;
export type PetitionUpdatePageData =
	| StarterPetitionUpdate
	| StaffPetitionUpdate
	| FeaturedNewsPetitionUpdate
	| DmResponsePetitionUpdate;
export type PetitionUpdateUser = PetitionUpdateDataUserFieldsFragment['user'];
export type PetitionUpdatePetition = PetitionUpdateDataPetitionFieldsFragment;
export type PetitionUpdateOrg = PetitionUpdateDataOrgFieldsFragment;

export async function getPetitionUpdatePageData(
	utilityContext: UtilityContext,
	{
		petitionSlugOrId,
		updateId,
		userId,
		countryCode,
		locale,
	}: { petitionSlugOrId: string; updateId: string; userId?: string; countryCode: string; locale: string },
): Promise<{ update: PetitionUpdatePageData; petitionSigned: boolean; petitionCreatedLessThanADayAgo: boolean }> {
	const {
		gql: { fetch },
	} = utilityContext;

	const [{ data }, signature] = await Promise.all([
		fetch<PetitionUpdateDataQuery, PetitionUpdateDataQueryVariables>({
			query: PetitionUpdateData,
			variables: {
				updateId,
				petitionSlugOrId,
				countryCode,
				locale,
			},
			rejectOnError: true,
		}),
		userId ? getViewerSignature(petitionSlugOrId, utilityContext) : undefined,
	]);

	if (!data?.petitionUpdate || data.petitionUpdate.__typename === 'MilestonePetitionUpdate') {
		throw new NotFoundError();
	}

	if (data.petitionUpdate.petition.status === 'UNDER_REVIEW' && !data.petitionUpdate.petition.viewerCanEdit) {
		throw new UnderReviewError();
	}

	return {
		update: data.petitionUpdate,
		petitionSigned: !!signature,

		petitionCreatedLessThanADayAgo: differenceInDays(new Date(), new Date(data.petitionUpdate.petition.createdAt)) < 1,
	};
}
